import axios from 'axios';

// 跨域认证信息 header 名
const xsrfHeaderName = 'Authorization';

const service = axios.create({
  timeout: 60000,
  // withCredentials: true,
  xsrfHeaderName,
  xsrfCookieName: xsrfHeaderName,
  // baseURL: "http://127.0.0.1:6001/",
  // baseURL: "http://nqmp.bizfocus.net/"
  baseURL: 'https://www.bizfocus.cn',
});

const err = (error) => {
  let msg = '网络异常';
  if (error.response) {
    let data = error.response.data;

    switch (error.response.status) {
      case 403:
      case 401:
        msg = data.msg;
        notification.error({
          message: '系统提示',
          description: data.msg,
          duration: 4,
        });
        break;
      case 500:
        msg = data.msg;
        console.log('------error.response------', error.response);
        break;
      case 404:
        msg = '很抱歉，资源未找到';
        notification.error({
          message: '系统提示',
          description: '很抱歉，资源未找到!',
          duration: 4,
        });
        break;
      case 504:
        msg = '网络超时';
        notification.error({ message: '系统提示', description: '网络超时' });
        break;

      default:
        notification.error({
          message: '系统提示',
          description: data.msg,
          duration: 4,
        });
        break;
    }
  } else {
    notification.error({
      message: '系统提示',
      description: '网络超时',
      duration: 4,
    });
  }
  return Promise.reject(msg);
};


async function postAction(url, data, config) {
  return new Promise((resolve, reject) => {
    service.post(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

async function getAction(url, data, config) {
  return new Promise((resolve, reject) => {
    service.get(url, { params: data, ...config }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

async function putAction(url, data, config) {
  return new Promise((resolve, reject) => {
    service.put(url, data, config).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
}

async function delAction(url, params, config) {
  return new Promise((resolve, reject) => {
    service.delete(url, { params, ...config }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        reject(error);
      },
    );
  });
}



export default service;

export {
  postAction,
  getAction,
  delAction,
  putAction,
};
