<template>
    <div>
        <div class="news-head-line">
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <!-- <img class="news-img" src="../../assets/image/news/05-2img.png"/> -->
            <div class="news-box">
                <div class="title">{{ newsDetail.title }}</div>
                <div class="date">{{newsDetail.date}}</div>
                <div class="line"></div>
                <div class="content" v-html="newsDetail.content">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
import { getAction } from '@/utils/request.js';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo(){
        // this.init()
        return {
            meta: this.metaData
        }
    },
    data() {
        return {
            newsDetail:{
                title:'',
                date:'',
                content:'',
            },
            metaData: []
        };
    },
    mounted() {
        setTimeout(()=>{
            this.refreshIndexInfo({data:'5' , news:true});
        },500)
        // this.init()
        this.init()
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        async init(){
            const res = await getAction(`/wp/wp-json/wp/v2/posts/${this.$route.query.id}`);
            if(res){
                // console.log('RES',res)
                let description = res.excerpt.rendered.split('<p>')[1].split('</p>')[0]
                let tages = res.tags
                let metacontent = ''
                const res2 = await getAction(`/wp/wp-json/wp/v2/tags`);
                if(res2){
                    let meta = res2.filter(item => {
                        for(let i = 0; i < tages.length;i++){
                            if(tages[i] == item.id){
                                return true
                            }
                        }
                    })
                    let metaList = meta.map(item=> {return item.name})
                    metacontent = metaList.join(',')
                }
                this.metaData= [
                { name:'keywords', content:metacontent },
                { name:'description', content:description }
                ]
                this.newsDetail.title = res.title.rendered
                let date = res.date.split('T')
                let newDate = date[0].split('-')[0] + '/' + date[0].split('-')[1] + '/' + date[0].split('-')[2]
                this.newsDetail.date = newDate
                this.newsDetail.content = res.content.rendered
                this.newsDetail.content = this.newsDetail.content.replaceAll("http://69.230.196.6:8088", "https://www.bizfocus.cn/wp")
            }
        },
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100rem;
    height: 30rem;
    margin-top: 4rem;
}
.news-box{
    width: 100rem;
    margin: auto;
    margin-top: 6rem; 
    .title{
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100rem;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100.1rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>
